import * as React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../../components/layout';

const BlogPage = ({ data }) => {
  return (
    <Layout>
      <Link to={'/'}>
        <div href=''>Home</div>
      </Link>
      <ul>
        {data.allMdx.nodes.map((node) => (
          <article key={node.id} className={'mx-16 my-24'}>
            <h2>
              <Link to={`/post/${node.slug}`}>{node.frontmatter.title}</Link>
            </h2>
            <p>Posted: {node.frontmatter.date}</p>
          </article>
        ))}
      </ul>
    </Layout>
  );
};

export const query = graphql`
  {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
        }
        id
        slug
      }
    }
  }
`;
export default BlogPage;
